<template>
<v-container class="pl-6">
  <v-row class="d-flex align-center ma-0 pa-2">
    <div style="text-align: center; background-color: #83ba5a; width: 28px; height: 28px;border-radius: 4px;">
      <v-icon color="#fff"> mdi-loupe </v-icon>
    </div>
    <span class="ml-3 text-h6">目标功能设置</span>
  </v-row>

  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1"> 目标自动结束</span>
    <v-switch v-model="settings.objectAutoComplete" @change="onChange"></v-switch>
  </v-row>
	<v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
    <span style="color:#8f9ca6;"> 开启后，当到达目标截止时间，系统自动更新目标状态为已结束 </span>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1"> 目标类型</span>
    <v-switch v-model="settings.objectType" @change="onChange"></v-switch>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1"> KR参与部门</span>
    <v-switch v-model="settings.objectKrParticipant" @change="onChange"></v-switch>
  </v-row>
	<v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
    <span style="color:#8f9ca6;"> 开启后，在公司级目标下的KR推屏中可以设置KR参与部门</span>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1"> 允许目标关联关键成果</span>
    <v-switch v-model="settings.objectReferKr" @change="onChange"></v-switch>
  </v-row>
	<v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
    <span style="color:#8f9ca6;"> 开启后，关联母目标时，可关联目标下的关键成果</span>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1"> E 周报</span>
    <v-switch v-model="settings.objectEReport" @change="onChange"></v-switch>
  </v-row>
	<v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
    <span style="color:#8f9ca6;"> 开启后，系统将在每周一自动生成执行数据报告 </span>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1"> 关键成果权重</span>
    <v-switch v-model="settings.objectKrRate" @change="onChange"></v-switch>
  </v-row>
	<v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
    <span style="color:#8f9ca6;"> 开启后，可以为关键成果设置权重 </span>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1"> 目标复盘</span>
    <v-switch v-model="settings.objectReply" @change="onChange"></v-switch>
  </v-row>
	<v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
    <span style="color:#8f9ca6;"> 开启后，可在每个目标下进行周期性复盘 </span>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1"> 目标关注者</span>
    <v-switch v-model="settings.objectFollower" @change="onChange"></v-switch>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1"> 关键成果进展</span>
    <v-switch v-model="settings.objectKrProgress" @change="onChange"></v-switch>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1"> 关键成果描述</span>
    <v-switch v-model="settings.objectKrDescription" @change="onChange"></v-switch>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1"> 目标描述</span>
    <v-switch v-model="settings.objectDescription" @change="onChange"></v-switch>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1"> 目标列表页展示工作执行</span>
    <v-switch v-model="settings.objectShowTask" @change="onChange"></v-switch>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1"> 关键成果信心指数</span>
    <v-switch v-model="settings.objectKrConfidence" @change="onChange"></v-switch>
  </v-row>

  <v-row class="pa-0 ma-0">
    <v-divider></v-divider>
  </v-row>
  <v-row class="d-flex align-center ma-0 pa-0">
    <span class="mr-3 text-subtitle-1"> KR 复盘</span>
    <v-switch v-model="settings.objectKrReply" @change="onChange"></v-switch>
  </v-row>
	<v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
    <span style="color:#8f9ca6;"> 开启后，可在每个 KR 下进行周期性复盘 </span>
  </v-row>

</v-container>
</template>

<script>
import {
  mapGetters,
  mapActions
} from "vuex";

export default {
  name: 'ObjectSettingTab',
  data() {
    return {
      gettedSetting: {},
      initSetting: {
        objectAutoComplete: true, // 目标自动结束
        objectType: true, // 目标类型
        objectKrParticipant: true, // KR参与部门
        objectReferKr: true, // 允许目标关联关键成果
        objectEReport: true, // E 周报
        objectKrRate: true, // 关键成果权重
        objectReply: true, // 目标复盘
        objectFollower: true, // 目标关注者
        objectKrProgress: true, // 关键成果进展
        objectKrDescription: true, // 关键成果描述
        objectDescription: true, // 目标描述
        objectShowTask: true, // 目标列表页展示工作执行
        objectKrConfidence: true, // 关键成果信心指数
        objectKrReply: true, // KR 复盘
      },
    };
  },
  computed: {
    settings: {
      get() {
        let val = {
          ...this.initSetting,
          ...this.gettedSetting,
        };
        return val;
      },
      set(newValue) {
      }
    }
  },
  mounted() {
    this.getSettingCall({
      "st_category_id": 0, //object
      "st_category_type": 101 //for this
    }).then(res => {
      if (res) {
        this.gettedSetting = res;
      }
    });
  },
  methods: {
    ...mapActions('app', ['getSettingCall', 'setSettingCall']),
    onChange() {
      this.setSettingCall({
        st_category_id: 0, //object
        st_category_type: 101, //for this
        st_value: this.settings
      }, ).then(res => {
      });
    },
  }
}
</script>

<style scoped>
</style>
